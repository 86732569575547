import { Injectable } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';

import { catchError, map , timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import CONFIG from './../../config';
import { AuthService } from './auth.service';
import { LoadingService } from '../loading-spinner/loading.service';


@Injectable()
export class BackendService {

    constructor(
        private _http: HttpClient,
        private _authService: AuthService,
        private _loadingService: LoadingService
    ) { }

    downloadFile(DocID, docRef2): Observable<Blob> {
        // let httpOptions =  new RequestOptions({responseType: ResponseContentType.Blob });
        // const httpOptions = {            
        //     headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${this._authService.token}`,
        //     responseType: 'blob'
        //     })
        // };
        var body = { filename: docRef2 };
        return this._http.post(`${CONFIG.documentsApiUrlBase}/${DocID}`, body, {
            reportProgress:true,
            responseType: "blob",
            observe:"body",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        })

       
           // .map(res => res as Blob);


        // return this._http.put(`${CONFIG.documentsApiUrlBase}/${DocID}`,httpOptions, {responseType: 'blob' as 'json'})
        // .pipe(
        //    catchError(this.handleError)
        // )
        //    .map(res => res as Blob)
        //    .catch(this.handleError)
    }

    deleteFile(DocID, docRef2): Observable<Blob> {
       
        var body = { filename: docRef2 };
 
    return this._http.delete(`${CONFIG.documentsApiUrlBase}/${DocID}`,{
        reportProgress:true,
        responseType: "blob",
        observe:"body",
        headers: new HttpHeaders().append("Content-Type", "application/json")
    })}


    //    download(fileId: string) {
    //     return this.http.get(Constants.downloadFile + `${fileId}`, {
    //       responseType: 'blob'
    //     });
    // }
    download(CustomerID) {
        console.log(`${CONFIG.imgApiUrlBase}/${CustomerID}/${this._authService.LoginType}`);
        return this._http.get(`${CONFIG.imgApiUrlBase}/${CustomerID}/${this._authService.LoginType}`, {
            responseType: 'blob',
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
    getfileData(url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token}`
            })
        };

        return this._http.get(url, httpOptions)
            .pipe(map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))
        // .catch(this.handleError)
        // .finally(() => {
        //     this._loadingService.hide();
        // });
    }
   






    getData(url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token}`
            })
        };

        return this._http.get(url, httpOptions)
            .pipe(map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))

        //     .catch(this.handleError)
        //     .finally(() => {
        //         this._loadingService.hide();
        //     });
    }

    deleteData(url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token}`
            })
        };
        // const httpOptions = new RequestOptions({ headers: headers });
        return this._http.delete(url, httpOptions)
            .pipe(map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))
        // .catch(this.handleError)
        // .finally(() => {
        //     this._loadingService.hide();
        // });
    }

    postData(newData, url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token}`,
                //  'secure':`${false}`,
                //  'timeout': `${500000}`,
            })
        };

        console.log(this._authService.token);
        const body = JSON.stringify(newData);
        console.log(url, body, httpOptions);
        return this._http.post(url, body, httpOptions)
            .pipe(map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))
        // .catch(this.handleError)
        // .finally(() => {
        //     this._loadingService.hide();
        // });
    }

    putData(updatedData, url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token}`
            })
        };
        const body = JSON.stringify(updatedData);
        return this._http.put(url, body, httpOptions)
            .pipe(map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))
        // .catch(this.handleError)
        // .finally(() => {
        //     this._loadingService.hide();
        // });

    }

    /*
    Utility error handler function for server errors
    */
    private handleError(error) {
        if (error.status && error.status === 401) {
            return Observable.throw('Session Expired- please log in');
        }

        return Observable.throw('Server Error');
    }

}