import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class FilterService {
    constructor() {}

    filterFromMultiple(items: Object, group: Array<Object>): Array<Object> {
        console.log(items,group);
        let itemsToLoop: Array<Object> = [];
        let filtered = group;
        let minDate = 0;
        let maxDate = 0;
        let date1, date2;

        //set up array of props from items object that have values
        //need to check for null and empty string instead of true/false, as filtering by checkbox will use a 'false' value!
        for(let item in items) {
            if(items[item] !== null && items[item] !== '') {
                itemsToLoop.push({
                    property: item,
                    value: items[item]
                });
            }
        }

        //check props array for Date objects- if exist, get min and max for range checking
        itemsToLoop.forEach(item => {
            if(item['value'] instanceof Date) {
                if(!date1) {
                    date1 = +item['value'];
                } else {
                    date2 = +item['value'];
                }

                maxDate = Math.max(date1, date2);
                minDate = Math.min(date1, date2);
            }
        });

        for(let i = 0, l = itemsToLoop.length; i < l; i += 1) {
            let propCheck = itemsToLoop[i]['property'];
            let valueCheck = itemsToLoop[i]['value'];
            let datePropName = '';

            //if a date is submitted in search params, find the property name of the array being searched where value = type Date to be used in filter below
            if(valueCheck instanceof Date) {
                for(let j = 0; j < 1; j += 1) {
                    for(let p in group[j]) {
                        if(group[j][p] instanceof Date) {
                            datePropName = p;
                            break;
                        }
                    }
                }
            }

            filtered = filtered.filter((property, index, arr) => {
                if(valueCheck instanceof Date) {
                    let thisDateAsNumber = +new Date(property[datePropName]);
                    return thisDateAsNumber >= minDate && thisDateAsNumber <= maxDate;
                } else {
                    return property[propCheck] === valueCheck;
                }
            });
        }
        console.log("filtered"+filtered);

        return filtered;
    }
}