import { Injectable, Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { AccountService } from '../shared/account.service';
import { BackendService } from '../shared/backend.service';
import CONFIG from '../../config';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { MatDialog , MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { MatNativeDateModule } from '@angular/material/';
// import { MatRippleModule } from '@angular/material/';
import { ContactUsDialogComponent } from '../menu/contact-us/contact-us.component';
import { SafeUrl,DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from 'app/notification/notification.service';

@Component({
    providers: [DecimalPipe],
    selector: 'dashboard-component',
    templateUrl: './dashboard.component.html',
    styles: [`
        .body-container {
            padding-bottom: 3.5rem;
        }
        .footer {
            width: 100%;
            padding: 1em;
            // background-color: #f7f7f9;
            // background-color:rgb(79, 174, 239);
            //background-color:rgb(37, 145, 217);
            box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16);
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
        }
        .footer a {
            color: inherit;
        }
        .footer .fa {
            transition: color 0.2s ease-in-out;
        }
        .twitter:hover {
            color: #1da1f2;
        }
        .facebook:hover {
            color: #3b5998;
        }
        .linkedin:hover {
            color: #0077b5;
        }
  `]
})
@Injectable()
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    currentSettlementAccountSubscription: Subscription;
    dropdownOpen: boolean = false;
    headerHeight: String;
    AccountName: string = '';
    LoginType: string = '';
    totalEntries: number = 0;
    accounts: any;
    accountnumber: string;
    scc: any;
    grid: any;
    originalItems: Object[] = [];
    @ViewChild('dashWrapper') dash;
    currentYear: number;
    activeSettlementAccount = 'Select account';
    animal: string;
    name: string;
    Issuer=false;
    image: SafeUrl | null = null;
    hideproxy:boolean=false;
    hideissuer:boolean=false;
    currentSAccount;
    issueridselection:any;
    noimage:boolean=false;


    constructor(
        public dialog: MatDialog,
        public decimalPipe: DecimalPipe,
        public formBuilder: FormBuilder,
        public backendService: BackendService,
        public authService: AuthService,
        public accountService: AccountService,
        public _backendService: BackendService,
        public _authService: AuthService,
        private sanitizer:DomSanitizer,
        public router:Router,
        public notificationService:NotificationService
    ) { this.accountnumber = ""; }

    ngOnInit() {
    // this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));

       // this.getAccountsDropdowns();
  
      
       this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
       console.log(this.currentSettlementAccountSubscription);
       this.currentSAccount = this.accountService.currentSettlementAccount;
       console.log(this.currentSAccount);


        this.LoginType = localStorage.getItem('LoginType') || sessionStorage.getItem('LoginType');
        this.AccountName = localStorage.getItem('CustomerID') || sessionStorage.getItem('CustomerID');
        this.currentYear = new Date().getFullYear();
        this.accountService.aClickedEvent
        .subscribe((data:string) => {
          this.getLogo(data);
        });
        console.log(this._authService.LoginType);
        if(this._authService.LoginType=="Issuer")
        {
            this.Issuer=true;
        }



          if(this.authService.LoginType=="Issuer")
          {
            if(this.authService.IssuerCount=="1")
            {
              this.getLogo(this.authService.singleIssuerID);
            }
            else
            {
              this.getLogo(this.currentSAccount);
            }
          }
          else if(this.authService.LoginType=="ADVISOR")
          {
            this.getLogo(this.authService.CustomerID);
            
          }
          else
          {
            if(this.authService.IssuerCount=="1")
            {
              this.getLogo(this.authService.singleIssuerID);
            }
            else
            {
              this.getLogo(this.currentSAccount);
            }
          }
         
          
            
         
                
        console.log(this._authService.CustomerType);
          if(this._authService.CustomerType=="ADVISOR")
            this.hideproxy=true;
          else
          this.hideproxy=false;

    }
    
    currentSettlementAccountChange(value) {
        this.currentSAccount = value;
        
      }
      btnPositions()
      {
       
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);
        if(this.authService.LoginType=="ADVISOR")
        {
          this.router.navigate(['/dashboard/positions']);
        }
        else
        {
                  if(this.authService.IssuerCount=="1")
                  {
                    this.router.navigate(['/dashboard/positions']);
                  }
                  else if(this.currentSAccount=="")
                  {
                      console.log("issuer id null"); 
                      this.notificationService.showNotification({ type: 'error', message: "You must first make a holder selection." });
                  }
                  else
                  {
                    console.log("else value");
                      this.router.navigate(['/dashboard/positions']);
                  
                  }
        }
      }
      btnTransactions()
      {
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);
        if(this.authService.LoginType=="ADVISOR")
        {
          this.router.navigate(['/dashboard/transactions']);
        }
        else
        {
            if(this.authService.IssuerCount=="1")
            {
              this.router.navigate(['/dashboard/transactions']);
            }
            else if(this.currentSAccount=="")
            {
                console.log("issuer id null"); 
                this.notificationService.showNotification({ type: 'error', message: "You must first make a holder selection." });
            }
            else
            {
              console.log("else value");
                this.router.navigate(['/dashboard/transactions']);
            
            }
      }
      }
      btnProxy()
      {
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);
        if(this.authService.LoginType=="ADVISOR")
        {
          this.router.navigate(['/dashboard/proxy']);
        }
        else
        {
            if(this.authService.IssuerCount=="1")
            {
              this.router.navigate(['/dashboard/proxy']);
            }
            else if(this.currentSAccount=="")
            {
                console.log("issuer id null"); 
                this.notificationService.showNotification({ type: 'error', message: "You must first make a holder selection." });
            }
            else
            {
              console.log("else value");
                this.router.navigate(['/dashboard/proxy']);
            
            }
          }
      }
      btnDocuments()
      {
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);
        if(this.authService.LoginType=="ADVISOR")
        {
          this.router.navigate(['/dashboard/document']);
        }
        else
        {
              if(this.authService.IssuerCount=="1")
              {
                this.router.navigate(['/dashboard/document']);
              }
              else if(this.currentSAccount=="")
              {
                  console.log("issuer id null"); 
                  this.notificationService.showNotification({ type: 'error', message: "You must first make a holder selection." });
              }
              else
              {
                console.log("else value");
                  this.router.navigate(['/dashboard/document']);
              
              }
            }
      }
      btnStatements()
      {
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);
        if(this.authService.LoginType=="ADVISOR")
        {
          this.router.navigate(['/dashboard/statements']);
        }
        else
        {
            if(this.authService.IssuerCount=="1")
            {
              this.router.navigate(['/dashboard/statements']);
            }
            else if(this.currentSAccount=="")
            {
                console.log("issuer id null"); 
                this.notificationService.showNotification({ type: 'error', message: "You must first make a holder selection." });
            }
            else
            {
              console.log("else value");
                this.router.navigate(['/dashboard/statements']);
            
            }
          }
        
      }
      btnreports()
      {
          console.log("reports click");
          this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
          console.log(this.currentSettlementAccountSubscription);
          this.currentSAccount = this.accountService.currentSettlementAccount;
          console.log(this.currentSAccount);
          console.log(this.authService.IssuerCount);
          if(this.authService.IssuerCount=="1")
          {
            console.log("1");
            this.router.navigate(['/dashboard/reports/print']);
          }
          else if(this.currentSAccount=="")
          {
               console.log("issuer id null"); 
               this.notificationService.showNotification({ type: 'error', message: "You must first make an Issuer selection." });
          }
          else
          {
            console.log("else value");
              this.router.navigate(['/dashboard/reports/print']);
           
          }

      }
      btndocuments()
      {
          console.log("documents click");
          this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
          console.log(this.currentSettlementAccountSubscription);
          this.currentSAccount = this.accountService.currentSettlementAccount;
          console.log(this.currentSAccount);
          if(this.authService.IssuerCount=="1")
          {
           
            this.router.navigate(['/dashboard/document/documents']);
          }
          else if(this.currentSAccount=="")
          {
               console.log("issuer id null"); 
               this.notificationService.showNotification({ type: 'error', message: "You must first make an Issuer selection." });
          }
          else
          {
            console.log("else value");
              this.router.navigate(['/dashboard/document/documents']);
           
          }

      }
    getLogo(value) {

      this.noimage=false;
      if(value=="")
        this.noimage=true;
    
        console.log("dash board image");
        const mediaType = 'application/image';
        this._backendService.download(value).subscribe(value => {
            const blob = new Blob([value], { type: mediaType });
            const unsafeImg = URL.createObjectURL(blob);
            this.image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        }, error1 => {
          this.noimage=true;
            console.log("download error");
        });
    }

    ngOnDestroy() {
        if(this.currentSettlementAccountSubscription)
         { this.currentSettlementAccountSubscription.unsubscribe(); }
        //  this.accountService.aClickedEvent
        // .unsubscribe();
    }
   
    btnreport(value)
    {
        console.log(value);
        this.router.navigate(['/dashboard/reports/print']);
    }


    public callMe(): void {
        // this.comp.filterAuditLog();
    }
    contactUS(): void {
        console.log('contactUSSS');
        let dialogRef = this.dialog.open(ContactUsDialogComponent, {
          width: '70%',
          data: { name: this.name, animal: this.animal }
        })
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.animal = result;
        })
      }
    filterAuditLog(item) {
        this.scc = item;
        let stringToSplit = this.scc;
        let x = stringToSplit.split("_");
        let value = x[0];
        console.log("entered filter");

        // this.backendService.getData(`${CONFIG.positionsApiUrlBase}/${this.authService.CustomerID}`)
        //     .subscribe(data => {
        //         this.grid = data['myCustomer'];

        //         this.originalItems = this.grid.filter(x => x.accountNumber === value);
        //         console.log(this.originalItems);
        //         this.grid = this.originalItems;
        //     });
        // this.comp.filterAuditLogForDashBoard(value);
        this.accountService.setSAccount(value);
    }
    resetAuditListing() {

        this.activeSettlementAccount = 'Select account';    
        this.accountService.setSAccount(this.activeSettlementAccount);
    }
    ngAfterViewInit() {
        this.headerHeight = `${this.dash.nativeElement.offsetHeight + 25}px`;
    }
    // getAccountsDropdowns() {
    //     this.backendService.getData(`${CONFIG.getAccountsDropdowns}/${this.authService.CustomerID}`)
    //         .subscribe(data => {
    //             this.accounts = data['accounts'];
    //             this.totalEntries = this.accounts.length;
    //         });
    // }

    // setValue(value: string) {
    //     this.accountnumber = value;
    // }

}    
