import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import CONFIG from '../../../config';
import { BackendService } from '../../shared/backend.service';
import { AuthService } from '../../shared/auth.service';
import { SearchService } from '../../shared/search.service';
import { OrderByService } from '../../shared/orderby.service';
import { PaginationService } from '../../shared/pagination.service';
import { FilterService } from '../../shared/filter.service';
import { Router } from '@angular/router';
import { Location, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Utilities } from '../../shared/utilities.service';
import { NotificationService } from '../../notification/notification.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { MatDialog } from '@angular/material/dialog';

import { debounceTime } from 'rxjs/operators';
import { AccountService } from 'app/shared/account.service';
@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent extends Utilities implements OnInit {
  loadingData: boolean = false;
  pagenlenth: boolean = true;
  active: string = '';
  noEntrys: boolean = false;
  items: Object[] = [];
  originalItems: Object[] = [];
  searchFilteredItems: Object[] = [];
  totalEntries: number = 0;
  dataCallback: Function;
  FilterByStatus: FormGroup;
  statusSubscription: Subscription;
  TextSearch: FormGroup;
  term: FormControl;
  allSubscription: Subscription;
  selected;
  entryRange: Array<number>;
  entryRangeSubscription: Subscription;
  apiUrl: string = '';
  searchArray: Array<string> = [];
  submitted: boolean = false;
  securities: any;
  defaultSortOrder: string = '';
  fields: string[] = [];
  fieldNames: string[] = [];
  exportItems: Object[] = [];
  securityIDList: any = [];
  securityTypeList: any = [];
  filetraudit: any;
  filetrSecurityTypeaudit: any;
  filtersecurityID: any;
  filtersecType: any;
  securityID = new FormControl();
  securityType = new FormControl();
  result: string = '';
  hideadvisor:boolean=false;
  hideadvid:boolean=false;
  issuerID:any;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  IssuerNameByID:any;

  constructor(
    public location: Location,
    public backendService: BackendService,
    public authService: AuthService,
    public _formBuilder: FormBuilder,
    public searchService: SearchService,
    public orderByService: OrderByService,
    public _router: Router,
    public paginationService: PaginationService,
    public filterService: FilterService,
    public numberPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,
    public utilities: Utilities,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public accountService:AccountService
  ) {
    super(_formBuilder, _router, location, notificationService, authService, backendService, dialog,);
  }

  ngOnInit() {

    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);



    if(this.authService.LoginType=="Issuer")
    {
      if(this.authService.IssuerCount=="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
    else if(this.authService.LoginType=="ADVISOR")
    {
      this.issuerID=this.authService.CustomerID;
     
    }
    else
    {
      if(this.authService.IssuerCount=="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }

    this.searchArray = ['customerID', 'customerName','securityID','issuer' ,'securityDescription','transactionNumber', 'currentShares', 'ownershipDate',  'originalCost'];
    this.defaultSortOrder = 'ownershipDate';
    this.fields = ['customerID', 'securityID', 'transactionNumber', 'currentShares', 'ownershipDate', 'originalShares', 'pricePerShare', 'originalSubscriptionAmount'];
    this.fieldNames = ['Account ID', 'Security ID', 'Cert. No', 'Shares/Pa', 'Ownership Date', 'Origin', ' Cost Basis', 'Original Costt'];
    this.serviceInit(this.formatDataForSearch);
    this.getSecurityDropdowns();
    console.log(this.authService.CustomerType);





    if(this.authService.CustomerType=="ADVISOR")
    {
      this.hideadvisor=true;
      this.hideadvid=false;
    }
    else
    {
      this.hideadvisor=false;
      this.hideadvid=true;
    }




    






  }

  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }

  exportDataOptions(submittedData?: Object[]) {
    console.log(this.originalItems);
    let name = 'Positions';
    let heading = 'Positions CSV';
    const data = submittedData || this.originalItems;
    console.log(data);
    this.exportData(data, name, heading);
  }

  exportData(submittedData?: Object[], name?: string, heading?: string) {

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: heading,
      useBom: true,
    //noDownload: true,
      headers: this.fieldNames,
      nullToEmptyString: true,
  };

  //  this.exportItems = submittedData;
    const data =submittedData;
    console.log(data);
    new Angular5Csv(data, name, options);

  }
  getSecurityDropdowns() {
    console.log(this.issuerID);
    this.backendService.getData(`${CONFIG.positionsApiUrlBase}/SecurityDropdowns/${this.issuerID}`)
      // .subscribe(response => {(`${CONFIG.getSecurityDropdown}/${this.authService.CustomerID}`)
      //     this.currency = response.map(item => item);
      .subscribe(response => {
        this.IssuerNameByID=response['issuerName'];
        console.log(this.IssuerNameByID);
        this.securities = response['data'];
        for (var i = 0; i < this.securities.length; i++) {
          var secID = this.securities[i].securityIDNumber;
          var secType = this.securities[i].securityIDType;
          //console.log(test);
          this.securityIDList.push(secID);
          this.securityTypeList.push(secType);
        }
        console.log(this.securities);
      });
  }


  serviceInit(dataCallback?: Function) {
    this.term = new FormControl();
    this.TextSearch = new FormGroup({
      term: this.term
    });

    this.FilterByStatus = this._formBuilder.group({
      'securityType': [],
      'securityID': []
    });

    this.getData();

    //if dataCallback is passed, give it the data and return- 
    //used if we need to do some extra formatting for a component
    if (dataCallback) { this.dataCallback = dataCallback; }

    //filter by text box search
    this.allSubscription = this.term.valueChanges
    .pipe(debounceTime(400))
      .subscribe(result => {
        let itemsToSearch = this.originalItems;

        this.searchService.findItemsFromMultiple(result, itemsToSearch, this.searchArray)
          .then(allFiltered => {
            if (result.length > 0) {
              this.items = allFiltered;
              this.searchFilteredItems = allFiltered;
            } else {
              this.items = this.originalItems;
            }
            this.totalEntries = this.items.length;
            this.setGrid(1, this.totalEntries);
          });
      });

    //subscription to update page numbering and currently showing array- uses pagination service
    this.entryRangeSubscription = this.paginationService.entryRange
      .subscribe(range => {
        this.entryRange = range;
        //if (!this.term.value) {
          this.items = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
          this.totalEntries = this.originalItems.length;
       // } else {
        //  this.items = this.searchFilteredItems.slice(this.entryRange[0], this.entryRange[1]);
        //  this.totalEntries = this.searchFilteredItems.length;
        //}
      });
  }

  getData() {
    //if we have a text search enabled and delete an item, the grid won't filter properly, so reset search first
    this.clearSearch();

    this.backendService.getData(`${CONFIG.positionsApiUrlBase}/${this.issuerID}`)
      .subscribe(response => {
        //all items- only changes here
        this.originalItems = response['myCustomer'];
        console.log(this.originalItems.length);
        this.items = this.originalItems;
        console.log(this.items);
        if (this.items.length === 0) {
          this.noEntrys = true;
        }
        if (this.dataCallback) {
          this.dataCallback(this.originalItems);
        }

        this.searchFilteredItems = this.originalItems;

        this.orderBy(this.defaultSortOrder);

        //full list for pagination, and list for each page
        this.items = this.originalItems;
        this.filetraudit = this.originalItems;
        this.totalEntries = this.items.length;
       
        this.setGrid(1, this.totalEntries);
      },
        error => {
          this.serverErrorOrTimeout(error);
        });
  }
  clearSearch() {
    this.term.setValue('');
    this.items = this.originalItems;
    this.searchFilteredItems = [];
  }

  setGrid(start: number, length?: number, value?: string) {
    const sentLen = length;
    this.loadingData=true;
    this.paginationService.setGrid(start, sentLen);
  }
  orderBy(param) {
    let itemsToOrder = this.originalItems;
    let sortingByCurrentShares = false;
    const altParam = param === this.defaultSortOrder ? undefined : this.defaultSortOrder;

    if (this.term.value) {
      itemsToOrder = this.searchFilteredItems;
    }

    //special case for currentShares- strip commas so orders correctly
    // if (param === 'currentShares') {
    //   console.log('ordering by current shares!');
    //   sortingByCurrentShares = true;
    //   itemsToOrder.forEach(item => {
    //     item['currentShares'] = item['currentShares'].replace(/,/gi, '');
    //   });
    // }

    this.items = this.orderByService.orderByParam(param, itemsToOrder, altParam);

    //re-format the currentShares to display as currency
    if (sortingByCurrentShares) {
      this.items.forEach(item => {
        item['currentShares'] = this.currencyPipe
          .transform(item['currentShares'], 'USD', false, '4.2-2')
          .slice(3);
      });
    }

    this.setGrid(1, itemsToOrder.length);
  }

  filtersecurityType() {
    //console.log(this.securityType.value);
    var data = this.FilterByStatus.controls['securityType'].value;
     console.log(data);
    //var securitytypedata=data;
    this.filtersecType = data;
    this.filetrSecurityTypeaudit = this.filetraudit.filter(x => x.issuer == this.filtersecType);
    console.log(this.filetrSecurityTypeaudit);
    this.totalEntries = this.filetrSecurityTypeaudit.length;
    this.setGrid(1, this.totalEntries);
    this.entryRangeSubscription = this.paginationService.entryRange
      .subscribe(range => {
        this.pagenlenth = true;
        this.items = this.filetrSecurityTypeaudit.slice(this.entryRange[0], this.entryRange[1]);
        this.exportItems = this.filetrSecurityTypeaudit;
        this.totalEntries = this.filetrSecurityTypeaudit.length;
        console.log( this.totalEntries);
        this.entryRange = range;
      });
  }
 
  filterSecurityID() {
    var data = this.FilterByStatus.controls['securityID'].value;
     console.log(data);
     console.log(this.originalItems);
     console.log(this.items);
     this.filetraudit=this.originalItems;
    this.filtersecurityID = data;
    console.log(this.filetraudit)
    this.filetraudit = this.filetraudit.filter(x => x.securityID == this.filtersecurityID);
    this.items = this.filetraudit;
    console.log(this.items);
    this.totalEntries = this.items.length;
    this.setGrid(1, this.totalEntries);
    this.entryRangeSubscription = this.paginationService.entryRange
      .subscribe(range => {
        this.items = this.filetraudit.slice(this.entryRange[0], this.entryRange[1]);
        this.exportItems = this.filetraudit;
        this.totalEntries = this.filetraudit.length;
        this.entryRange = range;
      });
  }
  clearSecurityID(){
    console.log("tets");
    console.log(this.originalItems);
    this.FilterByStatus.controls['securityID'].setValue('');
    this.setGrid(1, this.originalItems.length);
    this.entryRangeSubscription = this.paginationService.entryRange
    .subscribe(range => {
      this.items = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
      this.exportItems = this.originalItems;
      this.totalEntries = this.originalItems.length;
      this.entryRange = range;
    });
  }
  clearSecurityType(){
   
    console.log("tets");
    console.log(this.originalItems);
    this.FilterByStatus.controls['securityType'].setValue('');
    this.setGrid(1, this.originalItems.length);
    this.entryRangeSubscription = this.paginationService.entryRange
    .subscribe(range => {
      this.items = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
      this.exportItems = this.originalItems;
      this.totalEntries = this.originalItems.length;
      this.entryRange = range;
    });
  }
  // searchText() {
  //   this.result = this.TextSearch.controls['term'].value;
  //   console.log(this.result);
  //   console.log(this.originalItems);
  //   let itemsToSearch = this.originalItems;

  //   this.searchService.findItemsFromMultiple(this.result, itemsToSearch, this.searchArray)
  //     .then(allFiltered => {
  //       if (this.result.length > 0) {
  //         console.log("tetstst");
  //         this.items = allFiltered;
  //         this.searchFilteredItems = allFiltered;
  //       } else {
  //         console.log("12tetstst");
  //         this.items = this.originalItems;
  //       }
  //       this.totalEntries = this.items.length;
  //       this.setGrid(1, this.totalEntries);
  //     });
  // }

  searchText() {
    this.result = this.TextSearch.controls['term'].value;
    console.log(this.result);
    let itemsToSearch = this.originalItems;

    this.searchService.findItemsFromMultiple(this.result, itemsToSearch, this.searchArray)
        .then(allFiltered => {
            if (this.result.length > 0) {
                this.items = allFiltered;
                this.searchFilteredItems = allFiltered;
            } else {
                // this.searchFilteredItems = itemsToSearch;
                this.items = itemsToSearch;
            }
            this.orderBy(this.defaultSortOrder);
            this.totalEntries = this.items.length;
            this.loadingData = true;
            this.pagenlenth = false;
            // console.log(this.audit);
            // console.log(this.audit.length);
            // this.orderBy(this.currentSortOrder, this.audit.length);
            this.setGrid(1, this.items.length, "search");
            this.orderBy(this.defaultSortOrder);
            this.entryRangeSubscription = this.paginationService.entryRange
                .subscribe(range => {
                    console.log("subscription1");
                    this.entryRange = range;
                    // console.log("range" + this.entryRange[0], this.entryRange[1]);
                    if (!this.term.value) {
                        if (this.items.length == 0) {
                            this.items = itemsToSearch;
                        }
                        this.items = itemsToSearch.slice(this.entryRange[0], this.entryRange[1]);
                        this.exportItems = itemsToSearch;
                        this.totalEntries = itemsToSearch.length;
                    } else {
                        this.items = this.searchFilteredItems.slice(this.entryRange[0], this.entryRange[1]);
                        this.exportItems = this.searchFilteredItems;
                        this.totalEntries = this.searchFilteredItems.length;
                    }
                });
        });
}






  ngOnDestroy() {
    // this.orderByService.resetService();
    //this.allSubscription.unsubscribe();
    //this.entryRangeSubscription.unsubscribe();
  }

  formatDataForSearch(data) {
    // data.map(d => {
    //     //loop through and convert specific items that need it- others remain as-is
    //     d.rate = this.numberPipe.transform(d.rate, '1.4-4');
    //     d.originalCost = this.numberPipe.transform(d.originalCost, '1.2-2');
    //     d.costBasis = this.numberPipe.transform(d.costBasis, '1.2-2');
    //     d.currentShares = this.currencyPipe
    //         .transform(d.currentShares, 'USD', false, '4.2-2')
    //         .slice(3);
    // });
  }
}
