import { Component, OnInit } from '@angular/core';
import { PopupdisplayComponent } from '../popupdisplay/popupdisplay.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
import { DatePipe } from '@angular/common';
import { AccountService } from 'app/shared/account.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss']
})
export class ProxyComponent implements OnInit {
  panelOpenState = false;
  panelOpenState1 = false;
  favoriteLeader: string;
  opinion1: any; opinion: any;
  dialogRef: MatDialogRef<PopupdisplayComponent>;
  proxydata: any;
  proxydatalength:number;
  QuestionNoresponse:string[]=[];
  QuestionIDs:string[]=[];
  Questionresponse:string[]=[];
  Questionlist:string[]=[];
  SecurityIDlist:string[]=[];
  SecurityTypelist:string[]=[];
  postedfrom:string[]=[];
  postedto:string[]=[];
  loadingData: boolean = false;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  issuerID:any;

  Opinions: string[] = ['Yes', 'No', 'In Person', 'Proxy', 'Abstain'];
  Opinions1: string[] = ['Yes', 'No', 'In Person', 'Proxy', 'Abstain'];

  SubmitDate:any={};

  constructor(public dialog: MatDialog, public _router: Router,
    public authService: AuthService,
    public backendService: BackendService,
    public _datepipe:DatePipe,
    public accountService:AccountService
  ) {

  }

  ngOnInit() 
  {
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);

    if(this.authService.LoginType=="Issuer")
    {
      if(this.authService.IssuerCount=="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
    else if(this.authService.LoginType=="ADVISOR")
    {
      this.issuerID=this.authService.CustomerID;
      
    }
    else
    {
      if(this.authService.IssuerCount=="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
   


    this.pageLoad();
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }
  pageLoad() {
    console.log(this.authService.CustomerID);
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}`)
      .subscribe(response => {
        console.log(response);
        this.proxydata = response['myCustomer'];
        console.log(this.proxydata.length);
        if(this.proxydata.length==0)
        {
          this.loadingData=true;
        }
        this.proxydatalength=this.proxydata.length;
        console.log(this.proxydata);
        this.loadingData=true;
      });
  }

  SubmitVote(votes) 
  {
    console.log("Submit the response");
    this.SubmitDate.Questionlist=this.Questionlist;
    this.SubmitDate.QuestionIDs=this.QuestionIDs;
    this.SubmitDate.Questionresponse=this.Questionresponse;
    this.SubmitDate.SecurityIDlist=this.SecurityIDlist;
    this.SubmitDate.SecurityTypelist=this.SecurityTypelist;
    this.SubmitDate.postedfrom=this.postedfrom;
    this.SubmitDate.postedto=this.postedto;
    this.SubmitDate.QuestionNoresponse=this.QuestionNoresponse;
    console.log(this.SubmitDate);
    this.backendService.postData(this.SubmitDate,`${CONFIG.proxyApiUrlBase}/${this.issuerID}`)
    .subscribe(response => {
      console.log(response);
      if(response['result'])
        this.pageLoad();
      
        this.proxydata = response['myCustomer'];

    });




    // this.dialogRef = this.dialog.open(PopupdisplayComponent, {
    //   height: '140px',
    //   width: '600px',
    //   data: {},
    //   disableClose: true,

    // });
    // this.dialogRef.componentInstance.confirmMessage = "Your votes have been submitted. Thank you.";
    // this.dialogRef.afterClosed().subscribe(result => {
    //   if (result) {

    //     this._router.navigate(['/dashboard']);

    //   }
    //   this.dialogRef = null;
    // });
  }

  onItemChange(response,item) {
    console.log(response,item.questionID,item.questionDescription);
    console.log(item.noResponse);
    //this.getSelecteditem();

      if(this.QuestionIDs!=null)
      {
      for(let i=0;i<this.QuestionIDs.length;i++)
      { 
       if(this.QuestionIDs[i]==item.questionID)
       {
        this.QuestionIDs.splice(i,1);
        this.Questionresponse.splice(i,1);
        this.Questionlist.splice(i,1);
        this.SecurityIDlist.splice(i,1);
        this.SecurityTypelist.splice(i,1);
        this.postedfrom.splice(i,1);
        this.postedto.splice(i,1);
        this.QuestionNoresponse.splice(i,1);
       }
      }

      }
      
      this.QuestionIDs.push(item.questionID);
      this.Questionresponse.push(response);
      this.Questionlist.push(item.questionDescription);
      this.SecurityIDlist.push(item.securityID);
      this.SecurityTypelist.push(item.securityIDType);
      this.postedfrom.push(this._datepipe.transform(item.proxyPostedFrom.toString(),"MM/dd/yyyy"));
      this.postedto.push(this._datepipe.transform(item.proxyPostedTo.toString(),"MM/dd/yyyy"));
      this.QuestionNoresponse.push(item.noResponse);
    console.log(this.QuestionIDs,this.Questionresponse);

  }
}
