import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

import { DeviceDetectorService  } from 'ngx-device-detector';

//import { NavService } from './services/nav.service';
import { SearchService } from './shared/search.service';

import { LatestBrowserVersions } from './shared/browser-versions';


@Component({
  selector: 'app-root',
  templateUrl: './browser-update-template.html',
  styles: [`.alert {margin-bottom: 0rem;}`],
  providers: [DeviceDetectorService]
 // templateUrl: './app.component.html',
  // styles: [`
  //   :host {
  //     min-height: 100%;
  //   }
  // `]
})
export class AppComponent implements OnInit {
  modules;
  error: any;
  showHomeHeader: boolean = true;
  stickHomeHeader: boolean = false;
  routeSubscription: Subscription;
  deviceInfo: any;
  browserver: number;
  browsername: string;
  browserWarningOpen: boolean = false;
  IEbrowserWarningOpen: boolean = false;

  constructor(
      public location: Location,
      public router: Router,
      public renderer: Renderer2,
      public searchService: SearchService,
      private _deviceService: DeviceDetectorService ,
      //public navService: NavService
  ) {
      this.checkBrowserVersion();
      //searchService.svcRenderer = renderer;
  }

  checkBrowserVersion() {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    this.browserver = parseInt(this.deviceInfo.browser_version);
    this.browsername = this.deviceInfo.browser;
    const isMobile = this._deviceService.isMobile();
    const isTablet = this._deviceService.isTablet();
    const isDesktopDevice = this._deviceService.isDesktop();

    // console.log("deviceInfo"+this.deviceInfo+"browserver"+this.browserver+"browsername"+this.browsername);
    //  console.log(isMobile, isTablet, isDesktopDevice);

  if(this.browsername == 'IE'){
    if(LatestBrowserVersions[this.browsername] - 1 >= this.browserver) {
      this.IEbrowserWarningOpen = true;
    }  
  }
  else{
    if(LatestBrowserVersions[this.browsername] - 1 >= this.browserver) {
      this.browserWarningOpen = true; }  
    }   
  }

  ngOnInit() {
      this.routeSubscription = this.router.events
         // .filter(event => event instanceof NavigationEnd)
          .subscribe(event => {
              /*
              the homepage header/footer only really seem to apply to potential clients.  this hides them on any 'admin' related route- we can also use this to swap it out for an 'admin-related' header if necessary
              */
              //this.showHomeHeader = !event['url'].includes('admin');
              this.stickHomeHeader = event['url'] !== '/';
          });
  }

  ngOnDestroy() {
      if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
  }
  
  closeBrowserWarning(){
      this.browserWarningOpen = false;
  }
  closeIEBrowserWarning(){
    this.IEbrowserWarningOpen = false;
}
}
