import {Injectable} from '@angular/core';

@Injectable()
export class LoadingService {
    loaderHidden: boolean = true;
    timerHandle;

    constructor() {}

    show() {
        if(this.timerHandle || !this.loaderHidden) {
            return;
        }

        this.timerHandle = setTimeout(() => {
            this.loaderHidden = false;
        }, 500);
    }

    hide() {
        if(this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = undefined;
        }
        this.loaderHidden = true;
    }
}
