import { Injectable,EventEmitter,Output  } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class AccountService {
    currentSettlementAccount = '';
    @Output() aClickedEvent = new EventEmitter<string>();
    // Observable string sources
    private currentSettlementAccountChangeConfirmedSource = new Subject<string>();
  
    // Observable string streams
    currentSettlementAccountChange$ = this.currentSettlementAccountChangeConfirmedSource.asObservable();
  
    setSAccount(currentSettlementAccount: string) {

      this.currentSettlementAccount='Refersh';
      console.log(currentSettlementAccount);
      this.currentSettlementAccount = currentSettlementAccount;
      this.currentSettlementAccountChangeConfirmedSource.next(currentSettlementAccount);
    }
    AClicked(msg: string) {
      this.aClickedEvent.emit(msg);
    }

  
}