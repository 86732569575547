import CONFIG from '../../../config';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MultiColumnGridService } from '../../shared/multi-column-grid.service';
import { AccountService } from '../../shared/account.service';
import { AuthService } from '../../shared/auth.service';
import { BackendService } from '../../shared/backend.service';
import { FilterService } from '../../shared/filter.service';
import { NotificationService } from '../../notification/notification.service';
import { OrderByService } from '../../shared/orderby.service';
import { PaginationService } from '../../shared/pagination.service';
import { SearchService } from '../../shared/search.service';
import { Utilities } from '../../shared/utilities.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timeInterval, timeout } from 'rxjs/operators';


@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    providers: [DecimalPipe, PaginationService, DatePipe]
})
export class TransactionsComponent extends Utilities implements OnInit {
    currentSettlementAccountSubscription: Subscription;
    currentSAccount;
    loadingStatusSubscription: Subscription;
    loadingData: boolean = false;
    active: string = '';
    currency: any;
    accounts: Array<Object> = [];
    optionsUser: Array<any> = [];
    originalLog: Array<any> = [];
    preFilteredLog: Array<Object> = [];
    scc: any;
    result: string = '';
    selectedCurrency: string = '';
    transactionsFilter;
    filtersOpen: boolean = false;
    // currencyfilter:any;
    currencyInit: boolean = false;
    formateddate: any;
    DateFilter: FormGroup;
    datefilteroriginalitems: any;
    originalItemsforexport: Object[] = [];
    originalItemsforexport1: Object[] = [];
    pageload: boolean = false;
    TextSearch: FormGroup;

    items: Object[] = [];
    originalItems: Object[] = [];
    searchFilteredItems: Object[] = [];
    totalEntries: number = 0;
    dataCallback: Function;
    defaultSortOrder: string = '';

    entryRange: Array<number>;
    entryRangeSubscription: Subscription;
    fieldNames: string[] = [];
    term: FormControl;
    startdate = new FormControl();
    enddate = new FormControl();
    audit: Object[] = [];
    datefilteredaudit: Object[] = [];
    exportItems: Object[] = [];
    pagenlenth: boolean = true;
    searchArray: Array<string> = [];
    currentPage: string = '';
    sortingpageload: any;
    currentSortOrder: string = '';
    startdate1: string;
    enddate1: string;
    CustomerType:any;
    issuerID:any;


    constructor(
        public location: Location,
        public backendService: BackendService,
        public authService: AuthService,
        public accountService: AccountService,
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public searchService: SearchService,
        public orderByService: OrderByService,
        public router: Router,
        public paginationService: PaginationService,
        public filterService: FilterService,
        public numberPipe: DecimalPipe,
        public currencyPipe: CurrencyPipe,
        public datePipe: DatePipe,
        public dialog: MatDialog

    ) {
        super(formBuilder, router, location, notificationService, authService, backendService, dialog);
    }

    ngOnInit() 
    {
            this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
                console.log(this.currentSettlementAccountSubscription);
                this.currentSAccount = this.accountService.currentSettlementAccount;
                console.log(this.currentSAccount);



        if(this.authService.LoginType=="Issuer")
        {
          if(this.authService.IssuerCount=="1")
          {
            this.issuerID=this.authService.singleIssuerID;
          }
          else
          {
            this.issuerID=this.currentSAccount;
          }
        }
        else if(this.authService.LoginType=="ADVISOR")
        {
            this.issuerID=this.authService.CustomerID;
         
        }
        else
        {
            if(this.authService.IssuerCount=="1")
            {
              this.issuerID=this.authService.singleIssuerID;
            }
            else
            {
              this.issuerID=this.currentSAccount;
            }
        }




        this.currentPage = 'transactions';
        this.currentSortOrder = 'desc';
        this.enddate = new FormControl(new Date());
        this.startdate = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
        console.log(this.enddate.value, this.startdate.value);
        this.defaultSortOrder = 'transactionDate';
        this.serviceInit(this.formatDataForSearch);

        this.searchArray = ['transactionDate', 'transType', 'securityID', 'transactionNumber', 'price', 'amount','customerName'];
        this.fieldNames = ['SettlementDate', 'TransactionType', 'SecurityID', 'CertNo.', 'Price', 'Amount'];

        this.DateFilter = this.formBuilder.group({
            'startdate': [''],
            'enddate': ['']
        });
        this.term = new FormControl();
        this.TextSearch = new FormGroup({
            term: this.term
        });

        this.CustomerType=this.authService.CustomerType;


    }
    currentSettlementAccountChange(value) {
        this.currentSAccount = value;
        
      }
    exportDataOptions(submittedData?: Object[]) {
        console.log(submittedData);
        let name = 'Transactions';
        let heading = 'Transactions CSV';
        this.originalItemsforexport = submittedData;
        this.originalItemsforexport1 = this.originalItemsforexport;
        this.originalItemsforexport1.forEach((value, index
        ) => {
            // delete value['sdate'];
            // delete value['postingDate'];
            console.log(value, index);

        });

        console.log(this.originalItemsforexport);

        const data = this.originalItemsforexport1;
        this.localexportData(data, name, heading);
    }
    localexportData(submittedData?: Object[], name?: string, heading?: string) {

        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: heading,
            useBom: true,
            //noDownload: true,
            headers: this.fieldNames,
            nullToEmptyString: true,
        };

        const data = submittedData;
        //console.log(data);
        new Angular5Csv(data, name, options);

    }
    clearTextSearch() {
        console.log("");
        this.term.setValue('');
        this.exportItems = this.originalItems;
        this.audit = this.datefilteredaudit;
        this.searchFilteredItems = [];
        this.setGrid(1, this.datefilteredaudit.length);
        // this.entryRangeSubscription = this.paginationService.entryRange
        // .subscribe(range => {
        //   this.entryRange = range;
        //   if (1 <= 0) {
        //     if (this.audit.length == 0) {
        //       this.audit = this.datefilteredaudit;
        //     }
        //     this.audit = this.datefilteredaudit.slice(this.entryRange[0], this.entryRange[1]);
        //      console.log(this.audit);
        //     this.exportItems = this.datefilteredaudit;
        //     this.totalEntries = this.datefilteredaudit.length;
        
        //   } 
        //   else {
        //       this.entryRangeSubscription = this.paginationService.entryRange
        //       .subscribe(range => {
        //         //this.originalItems=this.audit;
        //         this.audit = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
        //         this.exportItems = this.originalItems;
        //         this.totalEntries = this.originalItems.length;
        //         console.log(this.totalEntries);
        //         this.entryRange = range;
        //       });
           
        //   }
        // });
    }
    searchText() {
        this.result = this.TextSearch.controls['term'].value;
        console.log(this.result);
        let itemsToSearch = this.datefilteredaudit;

        this.searchService.findItemsFromMultiple(this.result, itemsToSearch, this.searchArray)
            .then(allFiltered => {
                if (this.result.length > 0) {
                    this.audit = allFiltered;
                    this.searchFilteredItems = allFiltered;
                } else {
                    // this.searchFilteredItems = itemsToSearch;
                    this.audit = itemsToSearch;
                }
                this.orderBy(this.defaultSortOrder);
                this.totalEntries = this.audit.length;
                this.loadingData = true;
                this.pagenlenth = false;
                // console.log(this.audit);
                // console.log(this.audit.length);
                // this.orderBy(this.currentSortOrder, this.audit.length);
                this.setGrid(1, this.audit.length, "search");
                this.orderBy(this.defaultSortOrder);
                this.entryRangeSubscription = this.paginationService.entryRange
                    .subscribe(range => {
                        console.log("subscription1");
                        this.entryRange = range;
                        // console.log("range" + this.entryRange[0], this.entryRange[1]);
                        if (!this.term.value) {
                            if (this.audit.length == 0) {
                                this.audit = itemsToSearch;
                            }
                            this.audit = itemsToSearch.slice(this.entryRange[0], this.entryRange[1]);
                            this.exportItems = itemsToSearch;
                            this.totalEntries = itemsToSearch.length;
                        } else {
                            this.audit = this.searchFilteredItems.slice(this.entryRange[0], this.entryRange[1]);
                            this.exportItems = this.searchFilteredItems;
                            this.totalEntries = this.searchFilteredItems.length;
                        }
                    });
            });
    }

    serviceInit(dataCallback?: Function) {
        this.getData();
        if (dataCallback) { this.dataCallback = dataCallback; }

        //filter by text box search
        //   this.allSubscription = this.term.valueChanges
        //       .debounceTime(400)
        //       .subscribe(result => {
        //           let itemsToSearch = this.originalItems;

        //           this.searchService.findItemsFromMultiple(result, itemsToSearch, this.searchArray)
        //               .then(allFiltered => {
        //                   if(result.length > 0) {
        //                       this.items = allFiltered;
        //                       this.searchFilteredItems = allFiltered;
        //                   } else {
        //                       this.items = this.originalItems;
        //                   }
        //                   this.totalEntries = this.items.length;
        //                   this.setGrid(1, this.totalEntries);
        //               });
        //       });

        //subscription to update page numbering and currently showing array- uses pagination service
        //   console.log(this.audit);
        //   this.entryRangeSubscription = this.paginationService.entryRange
        //       .subscribe(range => {
        //           this.entryRange = range;
        //         //   if(!this.term.value) 
        //         //   {
        //               this.audit = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
        //               this.totalEntries = this.audit.length;
        //         //   } else {
        //         //       this.items = this.searchFilteredItems.slice(this.entryRange[0], this.entryRange[1]);
        //         //       this.totalEntries = this.searchFilteredItems.length;
        //         //   }
        //       });

    }


    getData() {
        //if we have a text search enabled and delete an item, the grid won't filter properly, so reset search first
        //this.clearSearch();
        console.log("Transaction page load.");
        this.backendService.getData(`${CONFIG.transactionsApiUrlBase}/${this.issuerID}`)
            .subscribe(response => {
                //all items- only changes here
                this.originalItems = response['myCustomer'];
                console.log(this.originalItems);
                if (this.dataCallback) {
                    this.dataCallback(this.originalItems);
                }
                this.searchFilteredItems = this.originalItems;
                this.orderBy(this.defaultSortOrder);

                //full list for pagination, and list for each page
                this.audit = this.originalItems;
                this.pagenlenth = false;
                this.totalEntries = this.audit.length;
                this.setGrid(1, this.totalEntries);

                this.dateFilter(this.enddate.value, this.startdate.value);
                setTimeout(() => { console.log("World!"); }, 2000);
                console.log(this.audit);
                //this.pagenlenth = false;
                //this.totalEntries = this.audit.length;
                //this.setGrid(1, this.totalEntries);
            },
                error => {
                    this.serverErrorOrTimeout(error);
                });
    }


    orderBy1(param) {
        console.log(this.audit);
        console.log(param);
        var itemsToOrder = this.audit;
        const altParam = param === this.defaultSortOrder ? undefined : this.defaultSortOrder;
        console.log(param, itemsToOrder, altParam);
        this.items = this.orderByService.orderByParamAscending(param, itemsToOrder, altParam);
        console.log( this.items);
      }

    orderBy(param, length?: number) {
        console.log(param);
        console.log('order by ' + param);
        if (param !== '') {
            this.currentSortOrder = param;
        } else {
            param = this.defaultSortOrder;
            console.log(param);
        };
        var itemsToOrder = this.datefilteredaudit;
        //var itemsToOrder = this.audit;
        console.log(itemsToOrder);
        // let itemsToOrder = this.filteredBySettlementAccount;
        let sortingByCurrentShares = false;
        const altParam = param === this.defaultSortOrder ? undefined : this.defaultSortOrder;

        if (this.term.value) {
            itemsToOrder = this.searchFilteredItems;
        }
        //   console.log(this.items);

        if (length) {
            var itemsLength: number = length;
        } else {
            var itemsLength: number = itemsToOrder.length;
        }

        this.setGrid(1, itemsLength, "orderby");

        //special case for currentShares- strip commas so orders correctly

        if (this.sortingpageload !== "pageload") {
            //  console.log(param, itemsToOrder, altParam, this.currentPage)
            //this.items = this.orderByService.orderByParam(param, itemsToOrder, altParam, this.currentPage);
            this.items = this.orderByService.orderByParam(param, itemsToOrder, altParam, this.currentPage);
            this.audit = this.items;
            console.log(param, itemsToOrder, altParam, this.currentPage);
        }
        else {
            //   console.log(param, itemsToOrder, altParam, this.currentPage)
            if (this.currentPage == 'transactions') {
                console.log("2!!!!!!!!");
                console.log(param, itemsToOrder, altParam, this.currentPage);
                this.items = this.orderByService.orderByParam(param, itemsToOrder, 'desc', this.currentPage);
                this.audit = this.items;

            }
            else {
                console.log("3!!!!!!!!");
                this.items = this.orderByService.orderByParamdes(param, itemsToOrder, altParam, this.currentPage);
                this.audit = this.items;
                console.log(param, itemsToOrder, altParam, this.currentPage);
            }

        }

    }

    setGrid(start: number, length?: number, value?: string) {
        console.log("setGrid");
        const sentLen = length;
        this.loadingData = true;
        this.paginationService.setGrid(start, sentLen);
    }


    // SearchOpen() {
    //     if (this.filtersOpen == false) {
    //         this.filtersOpen = true;
    //         this.dateFilter(this.enddate.value, this.startdate.value);
    //         //this.clearDateFilter()
    //     }
    //     else {
    //         this.filtersOpen = false;
    //         this.clearTextSearch();
    //         this.enddate = new FormControl(new Date());
    //         this.startdate = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
    //         this.dateFilter(this.enddate.value, this.startdate.value);
    //         //this.clearDateFilter()

    //     }
    // }
    clearDateFilter() {

        // this.fromInput.value = '';
        // this.toInput.value = '';
        this.audit = this.originalItems;

        this.totalEntries = this.audit.length;
        this.loadingData = true;
        this.pagenlenth = false;
        this.setGrid(1, this.audit.length, "search");
        this.orderBy(this.defaultSortOrder);
        this.entryRangeSubscription = this.paginationService.entryRange
            .subscribe(range => {
                console.log("subscription3");
                this.entryRange = range;
                // console.log("range" + this.entryRange[0], this.entryRange[1]);
                console.log(this.term.value);
                if (!this.term.value) {
                    if (this.audit.length == 0) {
                        this.audit = this.originalItems;
                    }
                    this.audit = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
                    this.exportItems = this.originalItems;
                    this.totalEntries = this.originalItems.length;
                } else {
                    this.audit = this.searchFilteredItems.slice(this.entryRange[0], this.entryRange[1]);
                    this.exportItems = this.searchFilteredItems;
                    this.totalEntries = this.searchFilteredItems.length;
                }
            });
    }

    startDateFilter() {
        console.log("started date filter");
        this.dateFilter(this.enddate.value, this.startdate.value);
    }

    dateFilter(edate, sdate) {
        console.log(this.term.value);
        if (sdate == undefined) {
            sdate = this.startdate.value;
        }
        console.log(edate, sdate);
        var startdate = this.datePipe.transform(sdate, "MM/dd/yyyy");
        var enddate = this.datePipe.transform(edate, "MM/dd/yyyy");
        // if(this.term.value!=null)
        // {
        //     console.log("serach after date filter");
        //     this.result = this.TextSearch.controls['term'].value;
        // console.log(this.result);
        // let itemsToSearch = this.originalItems;
        // this.searchService.findItemsFromMultiple(this.result, itemsToSearch, this.searchArray)
        //     .then(allFiltered => {
        //         if (this.result.length > 0) {
        //             this.audit = allFiltered;
        //             this.searchFilteredItems = allFiltered;
        //         } else {
        //             // this.searchFilteredItems = itemsToSearch;
        //             this.audit = itemsToSearch;
        //         }
        //     });
        //     this.datefilteroriginalitems = this.searchFilteredItems;
        // }
        // else
        this.datefilteroriginalitems = this.originalItems;
        console.log(this.datefilteroriginalitems);
        let startInclude: any = '';
        let endInclude: any = '';
        
        startInclude = new Date(sdate);
        endInclude = new Date(edate);
        
        let submit =
        {
            'startdate': startInclude,
            'enddate': endInclude
        }
        console.log(submit, startInclude, endInclude);
        
        this.originalItems.forEach(entry => {
            entry['transactionDate'] = new Date(entry['transactionDate']);
        });
        
        this.audit = this.filterService.filterFromMultiple(submit, this.originalItems);
        this.datefilteredaudit = this.filterService.filterFromMultiple(submit, this.originalItems);
        this.orderBy(this.defaultSortOrder);



        if (this.term.value) {
            this.result = this.TextSearch.controls['term'].value;
            let itemsToSearch = this.datefilteredaudit;
            this.searchService.findItemsFromMultiple(this.result, itemsToSearch, this.searchArray)
                .then(allFiltered => {
                    if (this.result.length > 0) {
                        this.audit = allFiltered;
                        this.datefilteredaudit = allFiltered;
                    }
                    this.totalEntries = this.audit.length;
                    this.loadingData = true;
                    this.pagenlenth = false;
                }
            );
        }
        
        // this.audit = this.datefilteroriginalitems.filter(x => x.transactionDate >= startdate && x.transactionDate <= enddate)
        // console.log(this.audit);
        // this.datefilteredaudit = this.datefilteroriginalitems.filter(x => x.transactionDate >= startdate && x.transactionDate <= enddate)
        console.log(this.audit);
        this.totalEntries = this.audit.length;
       
        this.loadingData = true;
        this.pagenlenth = false;
       
        this.setGrid(1, this.audit.length, "search");
        
        console.log("!!!!!!!!");
        this.orderBy(this.defaultSortOrder);

       // this.orderBy(this.defaultSortOrder);
        this.entryRangeSubscription = this.paginationService.entryRange
            .subscribe(range => {
                console.log("subscription4");
                this.entryRange = range;
               
                console.log("range" + this.entryRange[0], this.entryRange[1]);
                // if (1 <= 0) {
                //     if (this.audit.length == 0) {
                //         this.audit = this.originalItems;
                //         console.log('this.audit' + this.audit.length);
                //     }
                //     this.audit = this.originalItems.slice(this.entryRange[0], this.entryRange[1]);
                //     this.exportItems = this.originalItems;
                //     this.totalEntries = this.originalItems.length;
                //     console.log('this.audit' + this.audit.length);
                // }

                // else 
                //this.orderBy(this.defaultSortOrder);
                {
                    console.log(this.datefilteredaudit);
                    console.log(this.audit);
                    
                   // this.datefilteredaudit=this.audit;
                    this.audit = this.datefilteredaudit.slice(this.entryRange[0], this.entryRange[1]);
                    this.exportItems = this.datefilteredaudit;
                    console.log('this.audit' + this.audit.length + '' + this.currentSortOrder);
                    this.totalEntries = this.datefilteredaudit.length;
                   //this.orderBy(this.defaultSortOrder);
                   console.log(this.totalEntries)
                }
                console.log(this.audit);
            });
           
    }


    formatDataForSearch(data) {
        console.log(data);
        if (data != null) {
            data.settlementAmount = this.numberPipe.transform(data.settlementAmount, '1.2-2');
            data.transactionDate = this.datePipe.transform(data.transactionDate, "MM/dd/yyyy");
            data.entryDate = this.datePipe.transform(data.entryDate, "MM/dd/yyyy");
        };

        // if (data != null) {
        //     data.settlementAmount = this.numberPipe.transform(data.settlementAmount, '1.2-2');
        // }
        // data.map(d => {
        //     d.transactionDate = this.datePipe.transform(d.transactionDate, "MM/dd/yyyy");
        //     d.entryDate = this.datePipe.transform(d.entryDate, "MM/dd/yyyy");
        // });
    }
}