import { Component, OnInit } from '@angular/core';
import CONFIG from '../../../config';
import { AuthService } from '../../shared/auth.service';
import { BackendService } from '../../shared/backend.service';
import { OrderByService } from '../../shared/orderby.service';
import { PaginationService } from '../../shared/pagination.service';
//import * as json2csv from 'json2csv';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { Subscription } from 'rxjs/Subscription';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'app/notification/notification.service';
import { FormBuilder } from '@angular/forms';
import { NavigationEnd,Router } from '@angular/router';
import { CurrencyPipe, Location } from '@angular/common';
import { Utilities } from 'app/shared/utilities.service';
import { SearchService } from 'app/shared/search.service';
import { MultiColumnGridService } from 'app/shared/multi-column-grid.service';
import { DocummentscontainerComponent } from './docummentscontainer/docummentscontainer.component';
import { AccountService } from 'app/shared/account.service';
import { Observable,  interval } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [PaginationService]
})
export class DocumentsComponent implements OnInit {

  apiUrl: string = '';
  fields: string[] = [];
  fieldNames: string[] = [];
  defaultSortOrder: string = '';
  currentSortOrder: string = '';
  documents: any;
  filteredDocuments: Object[] = [];
  fileslist: Object[] = [];
  totalEntries: number = 0;
  noEntrys: boolean = false;
  entryRange: Array<number>;
  entryRangeSubscription: Subscription;
  currentRoute: any;  
  exportTitle: string = 'Exported-Data';
  active: string = '';
  files: any[];
  count: number = 0;
  title: any;
  fileExt: any;
  DocID: any;
  UserID: any;
  loadingData: boolean = false;
  pagenlenth: boolean = true;
  alignstatus: boolean = false;
  hidegrid: boolean = true;
  reportGenerating: boolean = false;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  maxdocid: any;
  
  issuerID:any;

  constructor(
    public _formBuilder: FormBuilder,
    public router: Router,
    public _location: Location,
    public notificationService: NotificationService,
    public authService: AuthService,
    public backendService: BackendService,
    public dialog: MatDialog,
    public searchService: SearchService,
    public orderByService: OrderByService,
    public paginationService: PaginationService,
    public currencyPipe: CurrencyPipe,
    public accountService: AccountService

  ) {
    // super(_formBuilder,router,_location,notificationService,backendService,authService,dialog,
    //   searchService,orderByService,paginationService,currencyPipe);


    // const source=interval(60000);
    // this.entryRangeSubscription = source.subscribe(val => this.ngOnInit());
    router.events.filter(event => event instanceof NavigationEnd)
    .subscribe(event => { 
      this.currentRoute = event['url'];       
    });
  }

  ngOnInit() {

    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);

    // this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.
    // subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    this.apiUrl = CONFIG.documentsApiUrlBase;
    this.fields = ['documentDate', 'documentType', 'fileExt'];
    this.fieldNames = ['Date', 'Documents', 'FileType'];
    this.defaultSortOrder = 'documentDate';

    // if(this.authService.LoginType=="Shareholder")
    // {
    //   this.issuerID=this.authService.CustomerID;
    // }
    // else if(this.authService.IssuerCount=="1" && this.authService.LoginType=="Issuer")
    //   this.issuerID=this.authService.singleIssuerID;
    // else 
    //   this.issuerID=this.currentSAccount;


      if(this.authService.LoginType=="Issuer")
      {
        if(this.authService.IssuerCount=="1")
        {
          this.issuerID=this.authService.singleIssuerID;
        }
        else
        {
          this.issuerID=this.currentSAccount;
        }
      }
      else if(this.authService.LoginType=="ADVISOR")
      {
        this.issuerID=this.authService.CustomerID;
       
      }
      else
      {
        if(this.authService.IssuerCount=="1")
        {
          this.issuerID=this.authService.singleIssuerID;
        }
        else
        {
          this.issuerID=this.currentSAccount;
        }
      }
     






    this.getDocuments();
    // console.log(this.formatDataForSearch);
    // this.serviceInit(this.formatDataForSearch);
    console.log(this.authService.LoginType);
    if (this.authService.LoginType == "Issuer")
      this.alignstatus = true;
    else
      this.alignstatus = true;

      

  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }

  getDocuments(refresh?) {
    console.log(this.issuerID);

    this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}`)
      .subscribe(response => {
        this.documents = response["documents"];
        this.maxdocid=response["maxdocid"];
        console.log(this.documents);
        this.filteredDocuments = this.documents;
        this.totalEntries = this.filteredDocuments.length;
        this.loadingData = true;
        this.pagenlenth = false;
        if (this.filteredDocuments.length === 0) {
          this.noEntrys = true;
        }
        this.orderBy(this.defaultSortOrder, this.totalEntries);
        // this.setGrid(1, this.totalEntries);
        this.paginationSubscription();
      },
        error => {
          // this.serverErrorOrTimeout(error);
        });

   // this.alertmessagecheck();

  }
  // close(){

  //   this.reportGenerating = false;
  //   this.pagenlenth = false;
  //   console.log(this.pagenlenth,"close")
  //   }
  alertmessagecheck() {
    this.backendService.getData(`${CONFIG.documentsRefreshReportsApiUrlBase}/${this.currentSAccount}/${this.authService.LoginType}`)
      .subscribe(response => {
        console.log(response);
        // if (!response['result']) {
        //   this.pagenlenth = true;
        //   this.reportGenerating = true;
        //   console.log(this.pagenlenth,"return false")
          
        // }
        // else {
        //   this.pagenlenth = false;
        //   this.reportGenerating = false;
        //   console.log(this.pagenlenth,"return true")
        // }
      });
      
  }

  setGrid(start: number, length?: number) {
    let sentLen = length;

    if (length === undefined) {
      sentLen = this.documents.length;
    }
    this.paginationService.setGrid(start, sentLen);
  }
  paginationSubscription() {
    this.entryRangeSubscription = this.paginationService.entryRange
      .subscribe(range => {
        this.entryRange = range;
        console.log(this.entryRange[0])
        // this.filteredDocuments = this.documents;
        this.filteredDocuments = this.documents.slice(this.entryRange[0], this.entryRange[1]);
        this.totalEntries = this.documents.length;
        this.loadingData = true;
        this.pagenlenth = false;
      });
  }
  orderBy(param, length?: number) {
    if (param !== '') {
      this.currentSortOrder = param;
    } else {
      param = this.defaultSortOrder;
    };
    let itemsToOrder = this.documents;
    let sortingByCurrentShares = false;
    const altParam = param === this.defaultSortOrder ? undefined : this.defaultSortOrder;
    this.documents = this.orderByService.orderByParam(param, itemsToOrder, altParam,"","documetnsdesc");
    if (length) {
      var itemsLength: number = length;
      console.log('length')
    } else {
      var itemsLength: number = itemsToOrder.length;
    }

    this.setGrid(1, itemsLength);

  }
  //   const req = new HttpRequest('GET', '/download/file', {
  //   reportProgress: true,
  // });

  // http.request(req).subscribe(event => {
  //   // Via this API, you get access to the raw event stream.
  //   // Look for download progress events.
  //   if (event.type === HttpEventType.DownloadProgress) {
  //     // This is an download progress event. Compute and show the % done:
  //     const percentDone = Math.round(100 * event.loaded / event.total);
  //     console.log(`File is ${percentDone}% downloaded.`);
  //   } else if (event instanceof HttpResponse) {
  //     console.log('File is completely downloaded!');
  //   }
  // });

  exportData(submittedData?: Object[]) {
    //generate csv data- some more complicated components will pass custom data (Users, for one)
    //others using this grid will use originalItems
    const data = submittedData || this.filteredDocuments;
    const csvData = { data };

    //if field to include and custom titles passed, include those
    if (this.fields.length > 0) { csvData['fields'] = this.fields; }
    if (this.fieldNames.length > 0) { csvData['fieldNames'] = this.fieldNames; }

    //add the proper headers

    //  const csv = 'data:text/csv;charset=utf-8,' + json2csv(csvData);

    //create the link to click and automatically click it (must be a better way?)
    const link = document.createElement('a');
    //   link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', `${this.exportTitle}.csv`);
    document.body.appendChild(link); // Link must be in DOM for Firefox
    link.click();
    document.body.removeChild(link);
  }
  exportFile(item) {
    this.loadingData = false;
    this.DocID = item.docID;
    //console.log(this.DocID, item.docRef2, item);
    //this.UserID = this.authService.CustomerID;
    this.backendService.downloadFile(this.DocID, item.docRef2).subscribe(blob => {
      importedSaveAs(blob, item.docRef2 + "." + item.fileExt);
      this.loadingData = true;
    })
  }
  Deletefile(item)
  {this.loadingData = false;
    this.DocID = item.docID;
    console.log(this.DocID, item.docRef2, item);
    //this.UserID = this.authService.CustomerID;
    this.backendService.deleteFile(this.DocID, item.docRef2).subscribe(blob => {
     // importedSaveAs(blob, item.docRef2 + "." + item.fileExt);
      this.loadingData = true;
      if (this.currentRoute == "/dashboard/document") {
        this.router.navigate(['/dashboard/document/documents']);
      }
      else {
        this.router.navigate(['/dashboard/document']);
      }



  })
}

  //makes table rows expandable in mobile screensize
  makeActive(item) {
    this.active = this.active === item ? '' : item;
  }

}
