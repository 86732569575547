import { environment } from './environments/environment';
const apiUrlBase = environment.production ? 'https://stcaccess.stctransfer.com:9000/' : 'https://stcaccess.stctransfer.com:9000/'; // For Server

//const apiUrlBase = environment.production ? 'http://172.16.5.17:8000' : 'http://172.16.5.17:8000'; // For Test  Server
//const apiUrlBase = environment.production ? 'http://172.16.5.17:9000' : 'http://172.16.5.17:9000'; // For old Server
//const apiUrlBase = environment.production ? 'http://localhost:60339/' : 'http://localhost:60339/'; // Local
//const apiUrlBase = environment.production ? 'http://pdx3.mimics.us:9015' : 'http://pdx3.mimics.us:9015'; // For pdx3
//const apiUrlBase = environment.production ?  'http://localhost:51695/'  : 'http://localhost:51695/' ;

const CONFIG = {
    environment: 'full',
    loginApiUrlBase: `${apiUrlBase}/api/login`,
    tempcodeApiUrlBase: `${apiUrlBase}/api/tempcode`,
    profileApiUrlBase: `${apiUrlBase}/api/ModifyPassword`,
    sendemailApiUrlBase: `${apiUrlBase}/api/ForgotPassword`,
    usersApiUrlBase: `${apiUrlBase}/api/user`,
    getSecurityDropdowns: `${apiUrlBase}/api/positions/SecurityDropdowns`,  
    getAccountsDropdowns: `${apiUrlBase}/api/PFCustomerViewGrid/dropdowns`, 
    documentsApiUrlBase: `${apiUrlBase}/api/Documents`,
    contactUsApiUrlBase: `${apiUrlBase}/api/contactus`,
    positionsApiUrlBase: `${apiUrlBase}/api/positions`,
    transactionsApiUrlBase: `${apiUrlBase}/api/Transaction`,
    proxyApiUrlBase: `${apiUrlBase}/api/proxy`,
    reportApiUrlBase: `${apiUrlBase}/api/report`,
    AdvisorIDSelectionchnage: `${apiUrlBase}/api/AdvisorIDSelection`,
    StatementSecurityIDApiUrlBase: `${apiUrlBase}/api/StatementSecurityID`,
    imgApiUrlBase: `${apiUrlBase}/api/img`,
    uploadApiUrlBase: `${apiUrlBase}/api/upload`,
    documentsRefreshReportsApiUrlBase:`${apiUrlBase}/api/reportscheck`,
};

export default CONFIG;
