
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BackendService } from '../shared/backend.service';
import { AuthService } from '../shared/auth.service';
//import { NotificationService } from '../notification.service';
import { Utilities } from '../shared/utilities.service';

import CONFIG from 'config';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../notification/notification.service';
//import { ConfirmModifyComponent } from './confirmmodify/confirmmodify.component';

export interface SecurityQuestion {
    value: number;
    viewValue: string;
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent extends Utilities implements OnInit {
    result: any;
    msg: any;
    id: string;
    updatedProfile: FormGroup;
    Password: FormControl;
    profileData: any;
    passwordStrength: number = 0;
    updatingPassword: boolean = false;
    passwordConfirmed: boolean = true;
    submitted: boolean = false;
    selected_Q1: string = '';
    selected_Q2: string = '';
    securityquestions: SecurityQuestion[] = [
        { value: 1, viewValue: 'What was your childhood nickname?' },
        { value: 2, viewValue: 'What was your dream job as a child?' },
        { value: 3, viewValue: 'Where is your favorite place to travel?' },
        { value: 4, viewValue: "What is Mother's maiden name?" },
        { value: 5, viewValue: 'What was the name of the town/city you were born?' },
        { value: 6, viewValue: 'Which high school did you attend?' }
    ];
    resetQuestions: Object[] = [];
    constructor(
        public _formBuilder: FormBuilder,
        public _router:Router,
        public location: Location,
        public notificationService: NotificationService,
        public authService: AuthService,
        public backendService: BackendService,
        public dialog: MatDialog
    ) {
        super(_formBuilder, _router, location, notificationService, authService, backendService, dialog);
        this.setForm();

    }

    ngOnInit() {
        //console.log(this.securityquestions.length);
        this.getMyProfile();
        //console.log(this.authService.CustomerID);
        //this.getPasswordResetQuestions();
    }


    updateProfiledata(updatedProfile) {
        //console.log(updatedProfile, this.passwordConfirmed);
        this.submitted = true;
        //console.log(this.updatedProfile.valid, this.passwordConfirmed);
        if (this.updatedProfile.valid && this.passwordConfirmed) {
            this.backendService.postData(updatedProfile, `${CONFIG.profileApiUrlBase}/${this.authService.CustomerID}/${this.authService.UserID}/${this.authService.LoginType}`)
                .subscribe(
                    response => {
                        this.submitted = false;
                        if (!response['result']) {
                            //console.log("test alert");
                            this.notificationService.showNotification({ message: response['msg'], type: 'warning' });
                            this._router.navigate(['/dashboard/changePassword']);
                        }
                        else {
                            this.updateProfiledata2(updatedProfile);
                            //     this.openDialog(updatedProfile);
                            //     this.notificationService.showNotification({ message: response['msg'], type: 'success' });
                            // this.authService.logout();
                        }
                    },
                    error => {
                        this.serverErrorOrTimeout(error);
                    });
        } else {
            //validators handle this case
            //console.log('error with form');
            this.notificationService.showNotification({ message: "New password and Confirm password does not match.", type: 'warning' });
        }
    }
    updateProfiledata2(updatedProfile) {
        //console.log("fhsdkfsj");
        this.backendService.postData(updatedProfile, `${CONFIG.profileApiUrlBase}/update/${this.authService.CustomerID}/${this.authService.UserID}/${this.authService.LoginType}`)
            .subscribe(
                response => {
                    //console.log("response"+response);
                    //console.log(response);
                    if (response['result']) {
                        this.submitted = false;
                        this.notificationService.showNotification({ message: response['msg'], type: 'success' });
                        this.authService.logout();
                    }
                }, error => {
                    this.serverErrorOrTimeout(error);
                });
    }

    getMyProfile() {
console.log(this.authService.UserID);
        this.backendService.getData(`${CONFIG.profileApiUrlBase}/${this.authService.UserID}/${this.authService.CustomerID}/${this.authService.LoginType}`)
            .subscribe(profileData => {
               // console.log(profileData);
                if (!profileData) {
                    this.notificationService.showNotification({ timer: 5000, message: profileData['msg'], type: 'error', closeable: false });
                }
                for (let i = 0; i < this.resetQuestions.length; i += 1) {
                    if (this.resetQuestions[i]['viewValue'] === profileData['user'].pwdResetQuestion1) {
                        this.selected_Q1 = this.resetQuestions[i].toString();
                    } else if (this.resetQuestions[i]['viewValue'] === profileData['user'].pwdResetQuestion2) {
                        this.selected_Q2 = this.resetQuestions[i].toString();
                    }
                }

                this.updatedProfile.controls['PwdResetQuestion1'].setValue(profileData['user'].pwdResetQuestion1);
                this.updatedProfile.controls['PwdResetQuestion2'].setValue(profileData['user'].pwdResetQuestion2);
                this.updatedProfile.controls['PwdResetAnswer2'].setValue(profileData['user'].pwdResetAnswer2);
                this.updatedProfile.controls['PwdResetAnswer1'].setValue(profileData['user'].pwdResetAnswer1);

                this.updatedProfile.controls['ModifyPasword'].valueChanges
                    //.debounceTime(200)
                    .subscribe((value) => {
                        this.passwordConfirmed = value === '';
                        this.updatingPassword = value !== '';
                    });

                this.updatedProfile.controls['ConfirmPassword'].valueChanges
                    .subscribe((value) => {
                        this.passwordConfirmed = this.updatedProfile.controls['ModifyPasword'].value === value;
                    });
            },
                error => {
                    this.serverErrorOrTimeout(error);
                });

    }

    setSecurityQuestions(newInfo) {
        this.submitted = true;
        //console.log("gdfjhfjskfgb");
        if (this.updatedProfile.valid) {
            this.backendService.putData(newInfo, `${CONFIG.profileApiUrlBase}/updateprofilesecurityquestions`)
                .subscribe(response => {
                    if (response) {
                        //finish login process
                        this.submitted = false;
                        this.authService.loggedIn = true;

                        //remove the 'partiallogin' session variable that prevented further nav on reload
                        sessionStorage.removeItem('partial_login');

                        //hide form
                        //      this._loginService.resetQuestionsSet = true;

                        this.notificationService.showNotification({ message: response['msg'], type: 'success' });
                        this._router.navigate(['/main']);
                    } else {
                        this.notificationService.showNotification({ message: response['msg'], type: 'error' });
                    }

                }, error => {
                    this.serverErrorOrTimeout(error);
                });
        } else {
            //validators should handle this branch
        }
    }

    cancel() {
        // this._loginService.resetQuestionsSet = true;
        this._router.navigate(['/dashboard']);
        // this.authService.logout();
    }


    setForm() {
        this.updatedProfile = this._formBuilder.group({
            'Password': ['', Validators.minLength(3)],
            'ModifyPasword': ['', Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+.)(/]).{8,12}$') && Validators.minLength(3)],
            'ConfirmPassword': [''],
            'PwdResetQuestion1': [''],
            'PwdResetQuestion2': [''],
            'PwdResetAnswer1': [''],
            'PwdResetAnswer2': ['']
        });
    }
}
